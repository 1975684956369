import * as React from "react";
import "../styles/global.css";
import Layout from "../components/layout";
import Footer from "../components/footer";
import "@fontsource/ibm-plex-sans/300.css";
import "@fontsource/space-grotesk/700.css";
import "@fontsource/raleway/700.css";
import "@fontsource/raleway/400.css";
import Seo from "../components/seo";
import HighlightCard from "../components/highlightCard";

const IndexPage = () => (
  <div className="home">
    <Seo></Seo>

    <Layout
      header={
        <p className="large-text" id="home-description">
          I’m a design educator and a PhD candidate researching on how game
          design can promote pro-environmental behavior.
        </p>
      }
      /*
      content={
        <div>
          <div className="home-content">
            <div className="home-content-highlights">
              <div className="highlight-card-wrapper">
                <div className="highlight-card-line card1"></div>
                <div className="highlight-card">
                  <h2 className="highlight-card-name">Back from CMU </h2>
                  <p className="highlight-card-content">
                    I've recently completed the Fulbright VSR program and
                    returned from Carnegie Mellon HCII.{" "}
                    <a
                      href="https://roomy-truffle-699.notion.site/Fulbright-VSR-Ailecek-Pittsburgh-c3fe745dbe414f6ebeda3acc93e4ad4d"
                      target="_blank"
                    >
                      Here
                    </a>
                    &nbsp;are my tips for new Fulbrighters. 🚀
                  </p>
                </div>
              </div>
              <div className="highlight-card-wrapper">
                <div className="highlight-card-line card2"></div>
                <div className="highlight-card">
                  <h2 className="highlight-card-name">Oruba Case Study </h2>
                  <p className="highlight-card-content">
                    UX/UI/Branding work for a Healthtech company. View it on
                    &nbsp;
                    <a
                      href="https://www.behance.net/gallery/153608809/Oruba-Branding-UXUI-Social-Media"
                      target="_blank"
                    >
                      Behance
                    </a>
                    .
                  </p>
                </div>
              </div>
              <div className="highlight-card-wrapper">
                <div className="highlight-card-line card3"></div>
                <div className="highlight-card">
                  <h2 className="highlight-card-name">Teaching at TOBB ETU </h2>
                  <p className="highlight-card-content">
                    I'm teaching Third Year Design Studio and Computer Aided
                    Design at TOBB ETU Dept. of Industrial Design in the '22-'23
                    Fall Semester.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      }*/
    ></Layout>
    <Footer></Footer>
  </div>
);
export default IndexPage;
